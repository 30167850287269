exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-artists-index-jsx": () => import("./../../../src/pages/artists/index.jsx" /* webpackChunkName: "component---src-pages-artists-index-jsx" */),
  "component---src-pages-home-index-jsx": () => import("./../../../src/pages/home/index.jsx" /* webpackChunkName: "component---src-pages-home-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-indie-index-jsx": () => import("./../../../src/pages/indie/index.jsx" /* webpackChunkName: "component---src-pages-indie-index-jsx" */),
  "component---src-pages-partners-index-jsx": () => import("./../../../src/pages/partners/index.jsx" /* webpackChunkName: "component---src-pages-partners-index-jsx" */),
  "component---src-pages-registry-2023-bobtail-yearlings-yearlings-bobtail-jsx": () => import("./../../../src/pages/registry/2023/bobtail-yearlings/yearlings-bobtail.jsx" /* webpackChunkName: "component---src-pages-registry-2023-bobtail-yearlings-yearlings-bobtail-jsx" */),
  "component---src-pages-registry-index-jsx": () => import("./../../../src/pages/registry/index.jsx" /* webpackChunkName: "component---src-pages-registry-index-jsx" */)
}

